//翻页导航箭头的效果
class Paging {
    constructor() {
        this.init();
    }

    init() {
        
        // $(document).on('scroll', function(){

        //     let arrow_top = $('.direc-arrow').offset().top - $(window).scrollTop();

        //     let scroll_top = $(window).scrollTop();     //滚动的高

        //     let screen_height = $(window).height();     //屏高

        //     let arrow_height = $('.arrow-l').height();  //箭头的高

        //     let max_height = $('.direc-arrow').height() + $('.direc-arrow').offset().top - screen_height;

        //     let margin_top = 0;

        //     if(arrow_top < 0) {
        //         margin_top = (screen_height / 2 - arrow_top) - arrow_height / 2;
        //     }else{
        //         margin_top = (screen_height - arrow_top) / 2 - arrow_height / 2;
        //     }

        //     if(scroll_top >= max_height) {
        //         margin_top = ($('.direc-arrow').height() + arrow_top) / 2 - arrow_top;
        //     }

        //     $('.arrow-l,.arrow-r', '.direc-arrow').stop().animate({
        //         marginTop: `${margin_top}px`
        //     }, 300);

        // }).trigger('scroll');

        $(document).on('scroll', function () {

            if (!$('div').hasClass('direc-arrow')) {
                return false;
            }

            //箭头盒子距离浏览器可视区域顶部的距离
            let top = $('.direc-arrow').offset().top - $(window).scrollTop();
            //屏高
            const screenHeight = $(window).height();
            // 箭头盒子的高度
            const boxHeight = $('.direc-arrow span').height();
            // 箭头盒子的父盒子的高度
            const wrapBoxHeight = $('.direc-arrow').height();
            //滚动能够看到圣经内容块底部的最大高度
            let maxHeight = $('.direc-arrow').offset().top + wrapBoxHeight - screenHeight;

            //margin-top值
            let marginTop = 0;
            if (top >= 0) {
                marginTop = (screenHeight - top - boxHeight) / 2;
            } else {
                marginTop = (screenHeight / 2 - top) - boxHeight / 2;
            }

            if ($(window).scrollTop() >= maxHeight) {
                marginTop = (wrapBoxHeight + top - boxHeight) / 2 - top;
            }

            // $('.direc-arrow .arrow-l, .direc-arrow .arrow-r').css({
            //     'margin-top': marginTop
            // });
            $('.arrow-l, .arrow-r', '.direc-arrow').css({
                'margin-top': `${marginTop}px`
            });

        }).trigger('scroll');
    }
}

export default Paging;