class Tools {
    constructor() {
        this.init();
    }

    init() {

        // 阅读页 两列经文 自定义导航条
        this.read_nano_slide();

        // 阅读页 章节选择和搜索 悬浮
        this.read_choose_suspension();

        // 点击提示注释
        this.note_promat();
    }

    // 阅读页 两列经文 自定义导航条
    read_nano_slide() {
        $('.read-inner .nano').nanoScroller({scrollTop: '10px'});
    }

    // 阅读页 章节选择和搜索 悬浮
    read_choose_suspension() {
        $(window).on('scroll', function(){
            if($(window).scrollTop() >= 60) {
                $('.chapters-site,.search-site','.read-page-wrapper').slideUp();
                $('.read-page-wrapper').addClass('read-page');
            }else{
                $('.chapters-site,.search-site','.read-page-wrapper').slideDown();
                $('.read-page-wrapper').removeClass('read-page');
            }
        }).trigger('scroll');
    }

    // 点击提示注释
    note_promat() {
        $('.foo').on('click', function(){
            let temp = $(this).data('footnote');
            $('.footnotes-box .footnotes-body').html( $(`#${temp}`).html() );

            let x = $(this).offset().left - 111;
            let y = $(this).offset().top + 40;
            $('.footnotes-box').css({
                left: `${x}px`,
                top: `${y}px`
            }).stop().fadeOut(0,function(){
                $(this).stop().fadeIn()
            });
        });
        $('.footnotes-box .icon-clear').on('click', ()=>{
            $('.footnotes-box').fadeOut()
        });

        // 解释说明定位锚点
        scrollspyiPad($('.footnotes'), -72 , 800, false);
    }
}

export default Tools;
