// Array.from的兼容写法
if (!Array.from) {
    Array.from = function(iterable) {
        return Array.apply(this, iterable);
    }
}

import Nav from './Modules/Nav';
import Select from './Modules/Select';
import Share from './Modules/Share';
import Tools from './Modules/Tools';
import Paging from './Modules/Paging';

class App {
    constructor() {
        this.init();
    }

    init() {
        new Nav();
        new Select();
        new Share();
        new Tools();
        new Paging();
    }
}

new App();
