class Select {
    constructor() {
        this.init();
    }

    init() {

        // PC端弹窗
        this.choose_pc_modal();

        // 内容拷贝
        this.text_copy();

        // 手机端弹窗
        this.choose_mobile_modal();

        // 手机端点击滑动切换
        this.mobile_slide_toggle();
    }

    // PC端选择弹窗
    choose_pc_modal() {
        $('.select-field').each(function() {
            let _this = $(this);
            $(this).on('click', function() {
                $('.select-field').not(_this).find('.select-item').hide();
                $(this).parent('.choose').find('.select-item').toggle();
            });
        });

        // 选择内容 PC端
        // $('.select-item a').on('click', function() {
        //     $(this).parents('.select-field').find('input').val($(this).html());
        // });

        $(window).on('resize', function() {
            if (window.matchMedia('(max-width: 767px)').matches) {
                $('.select-field .select-item').hide();
            }
        }).trigger('resize');

    }

    // 内容拷贝
    text_copy() {
        // 手机端内容拷贝 (PC -> Phone)
        $('.select-field-mobile .select-item').each(function() {
            let temp = $(this).parents('.choose').find('.select-field .select-item').html();
            $(this).html(temp);
        });
        // 章选择拷贝（PC -> iPad）
        $('.chapters-item').each(function(){
            $(this).find('.ipad>ul').html( $(this).find('.pc>ul').html() );
        });
    }

    // 手机端弹窗
    choose_mobile_modal() {
        $('.select-field-mobile').on('click', function() {
            let lightBox = null;
            if (window.matchMedia('(max-width: 767px)').matches) {
                let _this = $(this);
                let lity_tpl = $("#modal-text .lity-tpl").html();
                let content = $("." + $(this).data("text")).html();
                let modal_text = $("#modal-text .modal-text-content");
                modal_text.find(".nano-content").html(content);
                lightBox = lity("#modal-text .modal-text-content", {
                    template: lity_tpl
                });
                $('.select-item a').on('click', function() {
                    lightBox.close();
                    // 选择内容 手机端
                    // _this.find('input').val($(this).html());
                });
                // ajax实现选择
                $(".chapters-item li a").click( function() {
                    $.ajax({
                        type: "POST",
                        data: "val=" + $(this).html() + "&action=chapters_choose",
                        url: ajax_object.ajax_url,
                        async: false
                    });
                });
                $(".section-choose ul li a").click( function() {
                    $.ajax({
                        type: "POST",
                        data: "section_num=" + $(this).html() + "&action=section_choose",
                        url: ajax_object.ajax_url,
                        async: false
                    });
                });
                // $(".language .language-item li a").click( function() {
                //     $.ajax({
                //         type: "POST",
                //         data: "term_id=" + $(this).data('term-id') + "&post_id=" + $(this).data( 'post-id' ) + "&action=language_item",
                //         url: ajax_object.ajax_url,
                //         async: false
                //     });
                // });
                // 自定義滾動條
                $(".modal-text-box .nano").nanoScroller({
                    alwaysVisible: true,
                    preventPageScrolling: true
                });
            } else {
                return false;
            }
        });
    }

    // 手机端点击滑动切换
    mobile_slide_toggle() {
        $(window).on('resize', function() {
            if (window.matchMedia('(max-width: 767px)').matches) {
                $('.nano-content').on('click', '.hidden-new', function() {
                    $('.chapters-item').stop().animate({
                        marginLeft: "-100%"
                    });
                    $('.bottom-line').stop().animate({
                        left: "48%"
                    });
                });
                $('.nano-content').on('click', '.hidden-old', function() {
                    $('.chapters-item').stop().animate({
                        marginLeft: "0px"
                    });
                    $('.bottom-line').stop().animate({
                        left: "0px"
                    });
                });
            } else {
                // 关闭弹出框
                $('.lity-wrap').trigger('click');
            }
        }).trigger('resize');
    }
}

export default Select;
