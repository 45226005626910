class Nav {
    constructor() {
        this.init();
    }

    init() {
        
        // 智能导航
        this.headroom_nav();

        // 手机端菜单切换
        this.mobile_nav();

        // 手机端拷贝PC端
        this.content_copy();

        // 菜单切换
        this.menu_toggle();

        // 搜索框切换 和 加遮罩
        this.search_toggle();

        // 底部鼠标悬浮文字变色
        this.footer_toggle();

        // 置顶
        this.go_to_top();
    }

    // 智能导航
    headroom_nav() {
        $(".page header").headroom();
    }

    // 手机端菜单切换
    mobile_nav() {
        $('#sideNavLeft').sideNav({
            edge: 'left',
            closeOnClick: false,
            zIndex: 997
        });

        $(window).on('resize', function() {
            $('body').css('width', '100%');
            if (window.matchMedia('(min-width: 1200px)').matches) {
                $('#sidenav-overlay').stop().hide();
                $(document).sideNav('hide');
            }
        });
    }

    // 手机端拷贝PC端
    content_copy() {
        $('.slide-search .nav-search').html($('.mobile-menu .nav-search').html());
        $('.mobile-menu .stair-menu').html($('#header-nav .stair-menu').html());
    }

    // 菜单切换
    menu_toggle() {
        // PC端二级菜单切换
        $('.stair-menu>li, .sub-menu', '#header-nav').each(function() {
            $(this).on('mouseenter', () => {
                $(this).find('.sub-menu').slideDown();
            });
            $(this).on('mouseleave', () => {
                $(this).find('.sub-menu').hide();
            });

            $(this).on('mouseenter', () => {
                $(this).siblings('a').css('border-bottom', '4px solid transparent');
            });
            $(this).on('mouseleave', () => {
                $(this).siblings('a').css('border-bottom', '4px solid #fff');
            });
        });

        // 手机端二级菜单切换
        $('.submenu-dropdown-toggle .icon-keyboard-arrow-down').each(function() {
            $(this).addClass('on off');
            $('.footer-submenu').addClass('on off');

            $(this).on('click', () => {
                if ($(this).hasClass('on')) {
                    $(this).removeClass('on').addClass('off');
                    $(this).css('transform', 'rotate(180deg)');
                } else {
                    $(this).removeClass('off').addClass('on');
                    $(this).css('transform', 'rotate(0deg)');
                }

                // 隐藏的左侧导航
                $(this).parents('li').find('.sub-menu').stop().slideToggle();

                // 底部的切换导航
                let oTarget = $(this).parents('.widget').find('.footer-submenu');
                if (oTarget.hasClass('on')) {
                    oTarget.removeClass('on').addClass('off');
                    oTarget.stop().slideDown();
                } else if (oTarget.hasClass('off')) {
                    oTarget.removeClass('off').addClass('on');
                    oTarget.stop().slideUp();
                }

                $(window).on('resize', () => {
                    if (window.matchMedia('(min-width:768px)').matches) {
                        $('.footer-submenu').show();
                    } else {
                        if (oTarget.hasClass('on')) {
                            oTarget.stop().hide();
                        } else if (oTarget.hasClass('off')) {
                            oTarget.stop().show();
                        }
                    }
                });
            });
        });
    }

    // 搜索框切换 和 加遮罩
    search_toggle() {
        $('#header-search .icon-search').on('click', function() {
            $('.slide-search').css('transform', 'translateY(0)');
            $('#strick-box').addClass('box-bg');
        });
        $('#strick-box').on('click', function() {
            $('.slide-search').css('transform', 'translateY(-110px)');
            $(this).removeClass('box-bg');
        });

        $('.slide-search .icon-clear').on('click', function(){
            $('#strick-box').trigger('click');
        });
    }

    // 底部鼠标悬浮文字变色
    footer_toggle() {
        $('.site-footer-menu-inner').on('mouseleave', function() {
            $(this).find('a').css('color', '#fff');
        });
        $(".site-footer-menu-inner div[class^='col-']").hover(
            function() {
                $(this).siblings().find('a').css('color', '#9ad6f3');
                $(this).find('a').css('color', '#fff');
            },
            function() {
                $(this).siblings().find('a').css('color', '#9ad6f3');
                $(this).find('a').css('color', '#fff');
            }
        );
    }

    // 置顶
    go_to_top() {
        let goToTop = $('.go-to-top');
        $(window).on('scroll', () => {
            if ($(window).scrollTop() > $(window).height()) {
                goToTop.fadeIn();
            } else {
                goToTop.fadeOut();
            }
        }).trigger('scroll');
        goToTop.on('click', () => {
            $('html,body').stop().animate({
                scrollTop: 0
            }, 600);
        });
    }
}

export default Nav;
