class Share {
    constructor() {
        this.init();
    }

    init() {
        // 给aiticle-item下面的p动态添加paragraph类
        this.add_article_css_style();

        // PC端全篇分享
        this.share_all_mobile();
        
        // 平板和手机端全篇分享
        this.share_all();

        // 选择文字后出现的分享框
        this.select_text_modal();
    }

    // 给aiticle-item下面的p动态添加paragraph类
    add_article_css_style() {
         $('.article-item>p').addClass('paragraph');
    }

    // PC端全篇分享
    share_all() {

        $('.icon-share-pc').on('click', function() {

            if (window.matchMedia('(min-width: 1200px)').matches) {
                // index.html >=1200
                let x = $(this).offset().left;
                let y = $(this).offset().top + 18;
                $('.page #share').css({
                    left: `${x}px`,
                    top: `${y}px`
                }).stop().fadeToggle();
            } else if (window.matchMedia('(min-width: 992px) and (max-width: 1199px)').matches) {
                // index.html >= 992
                let x = $(this).offset().left - 350;
                let y = $(this).offset().top + 18;
                $('.page #share').css({
                    left: `${x}px`,
                    top: `${y}px`
                }).stop().fadeToggle();
            }

            // 阅读页PC端
            let read_page_x = $(this).offset().left - 350;
            let y = $(this).offset().top + 18;
            $('.read-page-wrapper #share').css({
                left: `${read_page_x}px`,
                top: `${y}px`
            }).stop().fadeToggle();
        });

        $('.icon-clear').on('click', function() {
            $('#share').fadeOut();
        });

        $(window).on('resize', function() {
            $('.icon-clear').trigger('click');
        }).trigger('resize');
    }

    // 平板和手机端全篇分享
    share_all_mobile() {
        $('.share-mobile').html($('#share').html());

        $('.icon-share-mobile').on('click', function() {
            if (window.matchMedia('(max-width: 991px)').matches) {
                $('#sideNavBottom').sideNav({
                    // menuHeight: '150px',
                    edge: 'bottom',
                    closeOnClick: false,
                    zIndex: 997,
                });
            }
        });

        $('.icon-clear').on('click', function() {
            $(document).sideNav('hide');
        });

        $(window).on('resize', function() {
            $('.icon-clear').trigger('click');
        }).trigger('resize');
    }

    // 选择文字后出现的分享框
    select_text_modal() {
        $('.article-item .paragraph').on('mouseup', function(e) {
            let x = e.pageX + 20;
            let y = null;
            if( $(this).hasClass('read-paragraph') ) {
                y = e.pageY - 330;
            }else {
                y = e.pageY - 230;
            }

            let temp = document.selection ? document.selection.createRange().text : window.getSelection().toString();

            // if (document.selection) {
            //     //IE
            //     temp = document.selection.createRange().text;
            // } else {
            //     //ff chrom
            //     temp = window.getSelection().toString();
            // }

            if (temp) {
                $('#select-text-modal').css({
                    // left: `${x}px`,
                    top: `${y}px`
                }).stop().fadeIn();
            }

            return false;
        });

        $(document).on('mousedown', function() {
            window.getSelection().removeAllRanges();
            $('#select-text-modal').stop().fadeOut();
        });
    }
}

export default Share;
